import React from "react"

import aboutUsCartoon from "../assets/images/aboutUsCartoon.png"

const AboutIntroSection = () => {

    return (
                <section className='px-4 pt-12 pb-10 md:pb-12 md:pt-16 sm:px-6 lg:px-8'>
                <div className='mx-auto max-w-screen-xl'>

                    <div className='mx-auto w-full text-center max-w-screen-4xl xl:max-w-5xl'>
                    <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
                        About us
                    </p>
                    <h1 className='mt-4 text-4xl font-extrabold text-white md:mt-5 sm:text-5xl md:text-6xl'>
                        A small but mighty team excited to make an impact
                    </h1>
                    <p className='mx-auto mt-4 max-w-lg text-xl md:mt-5 text-dark-300 md:max-w-3xl'>
                       With multiple decades of experience building applications, microservices, data strategies and visualization tools, our team is ready to help.
                       </p>
                    </div>
                    <div className='mx-auto mt-12 max-w-screen-lg rounded-3xl shadow-xl'>
                        <img className='max-w-full h-auto align-middle rounded-3xl' src={aboutUsCartoon} /> 
                    </div>
                </div>
                </section>
    )
}

export default AboutIntroSection;

