import React from "react";

const DiagnalUpRightLightDarkSection = () => 
{

    return (<div className='relative w-full h-16 bg-dark-800 md:h-32 lg:h-48'>
    <svg className='absolute w-full h-full text-dark-900' preserveAspectRatio='none' viewBox='0 0 100 100' fill='currentcolor'>
      <polygon points='0,100 100,100 100,0'/>
    </svg>
  </div>
  )

}

export default DiagnalUpRightLightDarkSection;