import React from "react"

import { AboutIntroSection, DiagnalDownRightDarkLightSection, DiagnalDownRightLightDarkSection, DiagnalUpRightLightDarkSection,
         HandbookSection, ValuesSection, CTASection } from "../components"

const AboutView = () => {


    return (
        <>
            <AboutIntroSection />
            <HandbookSection />
            <ValuesSection />
            <CTASection />
        </>
    )
}

export default AboutView