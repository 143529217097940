import React from "react"
import { Link } from "react-router-dom";

const CTASection = () => {

return (
    <section className='py-12 px-4 mx-auto max-w-screen-xl md:py-16 sm:px-6 lg:px-8'>

        <div className="relative py-16 rounded-3xl bg-dark-700 lg:py-20">

        <svg className='absolute top-0 right-1/4 inset-y-0 h-full text-dark-700 w-1/4 z-20' preserveAspectRatio='none' viewBox='0 0 100 100' fill='currentcolor'>
            <polygon points='0,0 100,0 0,100'/>
        </svg>
        <div className='absolute w-1/2 inset-y-0 h-full left-1/2 bg-dark-800 z-10 rounded-r-3xl'></div>
        
        <div className="flex relative z-10 z-30 flex-col justify-center items-center px-4 mx-auto text-center sm:px-16 lg:flex-row lg:text-left">
            <div className="max-w-lg font-bold text-2xl sm:text-4xl lg:w-1/2">
            <h5 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl">
                Let's make something great together.
            </h5>
            </div>
            <div className="max-w-lg flex justify-center mt-10 lg:w-1/2 lg:mt-0 lg:justify-end">
            <Link className="flex justify-center items-center py-4 px-8 w-auto h-14 text-base font-semibold leading-snug bg-white rounded-full transition ease-in-out duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-900" to="/contact">
                Let's chat
            </Link>
            </div>
        </div>
        </div>
        </section>
    )
}

export default CTASection;