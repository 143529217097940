import React from "react"; 

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserMd, faVialVirus, faCity, faFileContract  } from "@fortawesome/free-solid-svg-icons"

const HomeIndustrySection = () => {

    return (<section className='px-4 pt-12 pb-10 mx-auto max-w-screen-xl md:pb-12 md:pt-16 sm:px-6 lg:px-8'>
 
    <div className='mx-auto w-full max-w-xl text-center lg:max-w-3xl md:max-w-2xl'>
      <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
        Our industries
      </p>
      <h2 className='mt-6 text-3xl font-extrabold text-white sm:text-4xl md:text-5xl'>
        Trusted by early-stage startups across industries
      </h2>
      <p className='mt-6 text-xl text-dark-300'>
         Our collective experience enables us to be effective in multiple sectors
      </p>
    </div>
  
    <div className='mx-auto mt-12 max-w-screen-lg lg:mt-14'>
      <div className="grid grid-cols-2 gap-4 md:gap-6 ">
  
          <div className='flex justify-center items-center w-full rounded-3xl transition duration-200 ease-in-out bg-dark-700 hover:bg-dark-800 hover:text-white text-dark-400'>
            <FontAwesomeIcon className="pl-1 h-12" icon={faUserMd} /> 
            <span className="text-2xl pl-4">Healthcare </span>
          </div>
      
          <div className='flex justify-center items-center w-full h-36 rounded-3xl transition duration-200 ease-in-out bg-dark-700 hover:bg-dark-800 hover:text-white text-dark-400'>
            <FontAwesomeIcon className="pl-1 h-12" icon={faVialVirus} /> 
            <span className="text-2xl pl-4">Life Sciences </span>
          </div>

          <div className='flex justify-center items-center w-full h-36 rounded-3xl transition duration-200 ease-in-out bg-dark-700 hover:bg-dark-800 hover:text-white text-dark-400'>
            <FontAwesomeIcon className="pl-1 h-10" icon={faCity} /> 
            <span className="text-2xl pl-4">Prop Tech </span>
          </div>

          <div className='flex justify-center items-center w-full h-36 rounded-3xl transition duration-200 ease-in-out bg-dark-700 hover:bg-dark-800 hover:text-white text-dark-400'>
            <FontAwesomeIcon className="pl-1 h-11" icon={faFileContract} /> 
            <span className="text-2xl pl-4">Death Tech </span>
          </div>
      
      </div>
    </div>
  </section>
  )

}

export default HomeIndustrySection;