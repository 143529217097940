import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMedium, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"


const Footer = () => {

    return (<footer className='py-12 px-4 md:py-16 bg-dark-900 sm:px-6 lg:px-8'>
    <div className='grid gap-8 mx-auto lg:max-w-screen-xl sm:max-w-3xl md:grid-cols-3 md:gap-y-12 lg:grid-cols-4'>
  
      <div className='flex flex-col lg:mx-auto'>
        <div className='flex items-center'>
          <a href="index.html" className='text-2xl font-black lg:block group'>
            <span className='text-white transition duration-200 ease-in-out group-hover:text-dark-300'>Longitudinal</span>
            <span className='transition duration-200 ease-in-out text-dark-300 group-hover:text-white pl-1'>Labs</span>
          </a>
        </div>
        <div className='mt-6 text-lg md:mt-8 text-dark-300'>
          &#169; {new Date().getFullYear()} Longitudinal Labs.
          <br /> All rights reserved.
        </div>
      </div>
  
      <div className='lg:mx-auto'>
        <h6 className='text-xl font-semibold text-white'>
          Get in touch
        </h6>
        <p className='mt-2 text-lg text-dark-300'>
          1111 Sixth Ave
          <br />San Diego, CA 92101
        </p>
        <p className='mt-6 text-lg text-dark-300'>
          (619) 327-9527
        </p>
        <p className='text-lg text-dark-300'>
          hello@longitudinal.io
        </p>
      </div>
  
      <div className='lg:mx-auto'>
        <h6 className='text-xl font-semibold text-white'>
          Company
        </h6>
        <ul className='mt-2 space-y-1 text-lg'>
          <li className='font-medium text-dark-300 hover:text-white'>
            <Link to='/'>
              Home
            </Link>
          </li>
          <li className='font-medium text-dark-300 hover:text-white'>
            <Link to='/about'>
              About us
            </Link>
          </li>
          <li className='font-medium text-dark-300 hover:text-white'>
            <Link to='/services'>
              Services
            </Link>
          </li>
          {/* <li className='font-medium text-dark-300 hover:text-white'>
            <Link to='/work'>
              Work
            </Link>
          </li> */}
          <li className='font-medium text-dark-300 hover:text-white'>
            <Link to='/contact'>
              Contact us
            </Link>
          </li>
        </ul>
      </div>
  
      <div className='sm:col-span-3 lg:col-span-1 lg:mx-auto'>
        <h6 className='text-xl font-semibold text-white'>
          Follow us 
        </h6>
        <p className='mt-2 text-lg text-dark-300'>
        </p>
        <div className='mt-4 w-full lg:mt-6'>
  
          <div className='flex justify-start space-x-4'>
          <a className="flex justify-center items-center w-12 h-12 rounded-full transition duration-300 ease-in-out bg-dark-700 hover:text-dark-900 hover:bg-white  text-dark-300" href="https://medium.com">
              <FontAwesomeIcon className="icon icon-tabler icon-tabler-brand-medium" icon={faMedium} />
          </a>
          <a className="flex justify-center items-center w-12 h-12 rounded-full transition duration-300 ease-in-out bg-dark-700 hover:text-dark-900 hover:bg-white  text-dark-300" href="https://github.com/orgs/longitudinal-labs" >
            <FontAwesomeIcon className="icon icon-tabler icon-tabler-brand-medium" icon={faGithub} />
          </a>
          <a className="flex justify-center items-center w-12 h-12 rounded-full transition duration-300 ease-in-out bg-dark-700 hover:text-dark-900 hover:bg-white  text-dark-300" href="https://www.linkedin.com/company/longitudinal-labs">
           <FontAwesomeIcon className="icon icon-tabler icon-tabler-brand-medium" icon={faLinkedin} />
          </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer