import React from "react";

import MVPDevelopmentImage from '../assets/images/features-alternating-simple-02.jpg'

const MvpDevelopmentSection = () => {

    return (
    
    <section className='pb-10 md:pb-12 bg-dark-900'>
        <div className='mx-auto w-full max-w-screen-xl'>
        <div className='px-0 mt-20 w-full md:mt-24 md:grid md:grid-cols-2 lg:gap-x-12 xl:gap-x-36 md:gap-8 lg:mt-32 lg:px-6'>
            
            <div className='flex flex-col justify-center px-4 sm:px-6 md:pr-0 lg:px-0'>
            <div>
                <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
                MVP Development
                </p>
            </div>
            <h3 className='mt-4 text-3xl font-extrabold text-white md:mt-6 sm:text-4xl lg:text-5xl'>
                Build the right pieces at the right time
            </h3>
            <p className='mt-4 text-xl md:mt-6 text-dark-300'>
                We understand your goal is to show fit quickly and confidently. Getting the right features to market on-time and on-budget is critical.      
            </p>
            <p className='mt-4 text-xl md:mt-6 text-dark-300'>
                However, building a MVP without understanding it's dependicies and trade-offs can sink an early-stage venture. In order to de-risk your business, it is important to evaluate these concerns against your overall objectives.      
            </p>
            </div>
    
            <div className='mt-10 md:mt-0 relative w-full md:rounded-l-3xl lg:rounded-r-3xl pt-11/12'>
            <img src={MVPDevelopmentImage} className='object-cover object-center absolute inset-y-0 w-full h-full shadow-xl md:rounded-l-3xl lg:rounded-r-3xl' />
            </div>
        </div>
        </div>
    </section>
    )

}

export default MvpDevelopmentSection;