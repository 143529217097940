import React from "react";

const ValuesSection = () => {

    return (<section className='px-4 pt-10 pb-12 md:pt-12 md:pb-16 sm:px-6 lg:px-8'>
    <div className='mx-auto w-full max-w-screen-xl'>
  
      <div className='mx-auto w-full max-w-xl text-center lg:max-w-3xl md:max-w-2xl'>
        <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
          Our values
        </p>
        <h2 className='mt-6 text-3xl font-extrabold text-white sm:text-4xl md:text-5xl'>
          The values that guide and define our work
        </h2>
        <p className='mt-6 text-xl text-dark-300'>
            Our core values not only help shape the way we interact with our clients, they emanate through our work.   
        </p>
      </div>
  
      <div className='grid gap-y-8 mt-12 lg:mt-16 lg:grid-cols-3 lg:gap-x-6 2xl:gap-x-12'>
  
        <div className='py-14 px-4 mx-auto w-full max-w-lg rounded-3xl shadow-xl lg:py-16 lg:py-20 bg-dark-700 lg:px-6 xl:px-12'>
          <div className="mx-auto max-w-sm text-center">
            <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-inner w-18 h-18 from-dark-800 to-dark-900'>
              <svg xmlns="http://www.w3.org/2000/svg" className="mx-auto w-10 h-10 text-dark-300" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <polyline points="3 17 9 11 13 15 21 7" />
                <polyline points="14 7 21 7 21 14" />
              </svg> 
            </span>
            <div className="mt-8 text-2xl font-bold text-white ">
              Betterment
            </div>
            <p className='mt-3 text-lg leading-relaxed text-dark-300'>
                We'll never settle for “good enough” when we know we can and should do better. We remain ambitious, growing ourselves and those around us.
            </p>
          </div>
        </div>
  
        <div className='py-14 px-4 mx-auto w-full max-w-lg rounded-3xl shadow-xl lg:py-16 lg:py-20 bg-dark-700 lg:px-6 xl:px-12'>
          <div className="mx-auto max-w-sm text-center">
            <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-inner w-18 h-18 from-dark-800 to-dark-900'>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-medal" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M12 4v3m-4 -3v6m8 -6v6" />
                <path d="M12 18.5l-3 1.5l.5 -3.5l-2 -2l3 -.5l1.5 -3l1.5 3l3 .5l-2 2l.5 3.5z" />
            </svg>
            </span>
            <div className="mt-8 text-2xl font-bold text-white ">
              Integrity
            </div>
            <p className='mt-3 text-lg leading-relaxed text-dark-300'>
                An honest culture that is inclusive, transparent and empathic does not happen overnight. It is and will always remain a top priority. 
            </p>
          </div>
        </div>


        <div className='py-14 px-4 mx-auto w-full max-w-lg rounded-3xl shadow-xl lg:py-16 lg:py-20 bg-dark-700 lg:px-6 xl:px-12'>
          <div className="mx-auto max-w-sm text-center">
            <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-inner w-18 h-18 from-dark-800 to-dark-900'>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-heart" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <path d="M19.5 13.572l-7.5 7.428l-7.5 -7.428m0 0a5 5 0 1 1 7.5 -6.566a5 5 0 1 1 7.5 6.572" />
                </svg>
            </span>
            <div className="mt-8 text-2xl font-bold text-white ">
              Pride
            </div>
            <p className='mt-3 text-lg leading-relaxed text-dark-300'>
                We've devoted our lives to technology. It is not "just a job" for us, it is our craft and livelihood. <br/><br/> <span className="mt-8 text-lg font-bold text-white "> We love what we do! </span>
            </p>
          </div>
        </div>


      </div>
    </div>
  </section>
  )

}

export default ValuesSection;