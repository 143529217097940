import HeroHome from "../assets/images/hero-home.jpg";

import { HeroServicesSection, FractionalCtoSection, DiagnalUpRightDarkLightSection, DiagnalDownRightLightDarkSection, MvpDevelopmentSection,
        MvpProcessSection, CTASection, DataServiceSection, DataServiceDetailSection, DiagnalUpRightLightDarkSection } from "../components";


const ServicesView = () => {


    return (
        <>
            <HeroServicesSection />
            <DiagnalUpRightDarkLightSection />
            <FractionalCtoSection  />
            <DiagnalDownRightLightDarkSection />
            <MvpDevelopmentSection />
            <MvpProcessSection />
            <DiagnalUpRightDarkLightSection />
            <DataServiceSection />
            <DataServiceDetailSection />
            <DiagnalUpRightLightDarkSection />
            <CTASection />
        </>
    )
}

export default ServicesView