import React from "react"

import whatweDoImage1 from "../assets/images/what-we-do-image-01.jpg"
import whatweDoImage2 from "../assets/images/features-alternating-simple-01.jpg"
import whatweDoImage3 from "../assets/images/hero-services-01.jpg"


const WhatWeDoSection = () => {

    return (

                <section className='py-10 px-4 md:py-12 bg-dark-800 sm:px-6 lg:px-8'>

                <div className='mx-auto max-w-xl text-center md:max-w-2xl lg:text-left lg:max-w-screen-xl'>
                <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
                    What we do
                </p>
                <div className='grid gap-6 mt-6 w-full lg:grid-cols-5'>
                    <div className='lg:col-span-3'>
                    <h2 className='text-3xl font-extrabold text-white sm:text-4xl md:text-5xl'>
                        Strategize and develop your technology roadmap, together.
                    </h2>
                    </div>

                    <div className='lg:col-span-2'>
                    <p className='text-xl text-dark-300'>
                        We offer the experience and know-how empowering early-stage companie to leverage technology and data across all aspects of their business
                    </p>
                    </div>
                </div>
                </div>

                <div className='grid gap-12 justify-center mx-auto mt-12 w-full lg:grid-cols-2 lg:gap-8 lg:mt-16 lg:max-w-screen-xl xl:max-w-screen-xl lg:justify-start'>

                <div>
                    
                    <div className='w-full'>
                    <div className='mx-auto max-w-lg lg:mr-auto xl:mx-auto'>
                        <div className='flex relative py-6 px-8 w-full rounded-3xl shadow-xl bg-dark-700 sm:px-10'>
                        <div className='text-center sm:flex sm:text-left'>
                            <div className='w-full sm:w-1/5'>
                            <div className="flex justify-center items-center mx-auto w-12 h-12 bg-gradient-to-r rounded-2xl text-dark-300 sm:mx-0 from-dark-800 to-dark-900">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plug" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <path d="M7 7h10v6a3 3 0 0 1 -3 3h-4a3 3 0 0 1 -3 -3v-6" />
                                <line x1="9" y1="3" x2="9" y2="7" />
                                <line x1="15" y1="3" x2="15" y2="7" />
                                <path d="M12 16v2a2 2 0 0 0 2 2h3" />
                                </svg>
                            </div>
                            </div>
                            <div className='mt-3 w-full sm:mt-0'>
                            <h5 className='text-lg font-semibold text-white'>
                                Fractional CTO
                            </h5>
                            <p className='mt-1 text-base text-dark-300'>
                                Early-stage companies may not need a full time CTO, but they do need technical leadership and a roadmap for their product deveopment and data analytic strategy.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className='mt-8 w-full lg:mt-5 xl:mt-8 2xl:mt-10 xl:pr-18 2xl:pr-28'>
                    <div className='mx-auto max-w-lg lg:mr-auto xl:mx-auto'>
                        <div className='flex py-6 px-8 w-full rounded-3xl shadow-xl bg-dark-700 sm:px-10'>
                        <div className='text-center sm:flex sm:text-left'>
                            <div className='w-full sm:w-1/5'>
                            <div className="flex justify-center items-center mx-auto w-12 h-12 bg-gradient-to-r rounded-2xl text-dark-300 sm:mx-0 from-dark-800 to-dark-900">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <polyline points="7 8 3 12 7 16" />
                                <polyline points="17 8 21 12 17 16" />
                                <line x1="14" y1="4" x2="10" y2="20" />
                                </svg>
                            </div>
                            </div>
                            <div className='mt-3 w-full sm:mt-0'>
                            <h5 className='text-lg font-semibold text-white'>
                                MVP Development
                            </h5>
                            <p className='mt-1 text-base text-dark-300'>
                                It takes experience to know what to build and when. We aim to reduce the cost and time it takes to get your minimum viable product to market.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className='mt-8 w-full lg:mt-5 xl:mt-8 2xl:mt-10'>
                    <div className='mx-auto max-w-lg lg:mr-auto xl:mx-auto'>
                        <div className='flex py-6 px-8 w-full rounded-3xl shadow-xl bg-dark-700 sm:px-10'>
                        <div className='text-center sm:flex sm:text-left'>
                            <div className='w-full sm:w-1/5'>
                            <div className="flex justify-center items-center mx-auto w-12 h-12 bg-gradient-to-r rounded-2xl text-dark-300 sm:mx-0 from-dark-800 to-dark-900">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-grid-dots" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                <circle cx="5" cy="5" r="1" />
                                <circle cx="12" cy="5" r="1" />
                                <circle cx="19" cy="5" r="1" />
                                <circle cx="5" cy="12" r="1" />
                                <circle cx="12" cy="12" r="1" />
                                <circle cx="19" cy="12" r="1" />
                                <circle cx="5" cy="19" r="1" />
                                <circle cx="12" cy="19" r="1" />
                                <circle cx="19" cy="19" r="1" />
                                </svg>                            
                            </div>
                            </div>
                            <div className='mt-3 w-full sm:mt-0'>
                            <h5 className='text-lg font-semibold text-white'>
                                Data Engineering & ML Services
                            </h5>
                            <p className='mt-1 text-base text-dark-300'>
                             Going from zero to insight can be difficult. We'd love to help with your data strategy from modeling, building pipeline architectures through to visualization and machine learning.
                            </p>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>

                <div className='flex justify-center items-center max-w-screen-md lg:max-w-full'>
                    <div className='grid grid-rows-2 grid-flow-col gap-4 h-full'>
                        <div className='row-span-2 rounded-3xl shadow-xl'>
                            <img src={whatweDoImage1} className='object-cover w-full h-full rounded-3xl position-right' />
                        </div>
                        <div className='rounded-3xl shadow-xl'>
                            <img src={whatweDoImage2} className='object-cover w-full h-full rounded-3xl position-center' />
                        </div>
                        <div className='rounded-3xl shadow-xl'>
                            <img src={whatweDoImage3} className='object-cover w-full h-full rounded-3xl position-center' />
                        </div>
                    </div>
                </div>
                </div>
                </section>


    );


}

export default WhatWeDoSection