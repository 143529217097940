import React from "react"

import { Link } from "react-router-dom";

import HeroHome from "../assets/images/hero-home.jpg";


const HomeHeroSection = () => {


    return (

        <section className='px-4 pt-6 pb-12 mx-auto max-w-screen-2xl md:pb-16 sm:px-6 lg:px-8'>  

        <div className="relative rounded-3xl shadow-xl sm:overflow-hidden bg-dark-700">

        <svg className='absolute right-1/4 inset-y-0 h-full text-dark-700 w-1/4 z-20' preserveAspectRatio='none' viewBox='0 0 100 100' fill='currentcolor'>
            <polygon points='0,0 100,0 0,100'/>
        </svg>
        <div className='absolute w-1/2 inset-y-0 h-full left-1/2 bg-dark-800 z-10 rounded-r-3xl'></div>

            <div className="relative py-16 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:auto-rows-max lg:gap-x-8 xl:gap-x-12 sm:px-6 sm:py-24 lg:py-32 lg:px-8">

                <div className='flex flex-col justify-center items-center lg:items-start'>
                <p className="inline-flex z-30 justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-800 to-dark-900">
                    Welcome
                </p>
                <div className='z-30 mx-auto mt-6 w-full max-w-lg text-center lg:text-left lg:max-w-none md:max-w-2xl'>
                    <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                    Let’s turn technology into your competitive advantage
                    </h1>
                    <p className="mt-6 text-xl text-dark-300">
                    Over the past decade, companies that truly embraced software are now market leaders. But just as <span className="italic">"software ate the world, now AI is eating software."</span>
                    <br /> <br />How well are you positioned to leverage today's technologies?
                    </p>
                </div>

                <div className='z-30 mt-10 sm:flex sm:justify-center lg:justify-start'>
                    <Link className="flex justify-center items-center py-4 px-8 w-auto h-14 text-base font-semibold leading-snug bg-white rounded-full transition ease-in-out duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-900" to="/contact">
                    Ready to talk?
                    </Link>
                    <Link className="flex relative justify-center items-center px-8 mt-5 ml-0 w-auto h-14 text-base font-medium leading-snug text-white sm:ml-4 sm:mt-0 group" to="/services">
                    <span className='block absolute top-0 left-0 z-10 w-full h-full bg-gradient-to-r rounded-full transition-all ease-in-out duration-250 from-dark-800 to-dark-900 md:w-14 md:group-hover:w-full'></span>
                    <span className="relative z-20">Learn more</span>
                    </Link>
                </div>
                </div>

                <div className='flex justify-center items-center mx-auto mt-12 max-w-xl sm:mt-16 lg:mt-0 lg:max-w-none'>
                    <img src={HeroHome} className='object-cover z-30 w-auto h-full rounded-3xl shadow-md' />
                </div>
            </div>
        </div>
        </section>    





    );
}

export default HomeHeroSection;