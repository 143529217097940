import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSitemap, faMapLocationDot, faMusic, faScrewdriverWrench, faChartColumn, faSignsPost, faBrain  } from "@fortawesome/free-solid-svg-icons"


const DataServiceDetailSection = () => {

    return (
<section className="px-4 pt-24 pb-10 md:pt-24 md:pb-12 bg-dark-800 sm:px-6 lg:px-8">
    <div className='flex flex-wrap items-center mx-auto w-full max-w-screen-xl'>
      <div className='flex flex-wrap w-full'>
  
        <div className='flex w-full relative w-full lg:w-1/3'>
          <div className='flex relative w-full'>
            <div className='flex relative flex-wrap w-full flex-start lg:flex-col'>
              <div className='relative w-full'>
                <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
                  What we offer
                </p>
                <h2 className='relative mt-6 w-full text-3xl font-extrabold text-left text-white sm:text-4xl md:text-5xl'>
                  Better Data <br /> Better Decisions
                </h2>
              </div>
            </div>
          </div>
        </div>
  
        <div className='lg:w-2/3 relative flex w-full mt-16 lg:pl-8'>
          <div className='grid gap-y-10 w-full sm:grid-cols-2 sm:gap-x-8 sm:gap-y-16'>
  
            <div className='flex relative flex-col w-full'>
              <span className='flex justify-center items-center w-16 h-16 bg-gradient-to-r rounded-3xl shadow-xl from-dark-600 to-dark-700'>
               <FontAwesomeIcon className="h-6 text-dark-400" icon={faSitemap} /> 
              </span>
              <h4 className='mt-4 text-2xl font-semibold text-white'>
                Modeling
              </h4>
              <p className='mt-1 max-w-md text-lg leading-relaxed text-dark-300'>
                  Proper data models have implications on accessibilty and cost. Understanding how you source and organize your information now and into the future is crucial.
              </p>
            </div>
  
            <div className='flex relative flex-col w-full'>
              <span className='flex justify-center items-center w-16 h-16 bg-gradient-to-r rounded-3xl shadow-xl from-dark-600 to-dark-700'>
               <FontAwesomeIcon className="h-6 text-dark-400" icon={faSignsPost} /> 
              </span>

              <h4 className='mt-4 text-2xl font-semibold text-white'>
                Lineage
              </h4>
              <p className='mt-1 max-w-md text-lg leading-relaxed text-dark-300'>
                Do you know where all your data is, where it came from or what's been done to it? Building rich context will help your organization discover, govern and secure assets.            
              </p>
            </div>
  
            <div className='flex relative flex-col w-full'>
                <span className='flex justify-center items-center w-16 h-16 bg-gradient-to-r rounded-3xl shadow-xl from-dark-600 to-dark-700'>
                    <FontAwesomeIcon className="h-6 text-dark-400" icon={faMusic} /> 
                </span>
              <h4 className='mt-4 text-2xl font-semibold text-white'>
                Orchestration
              </h4>
              <p className='mt-1 max-w-md text-lg leading-relaxed text-dark-300'>
                Reliably moving and accurately preparing data requires a symphony of components. Don't let it continue to be a hurdle for your business to overcome.
               </p>
            </div>
  
            <div className='flex relative flex-col w-full'>
            <span className='flex justify-center items-center w-16 h-16 bg-gradient-to-r rounded-3xl shadow-xl from-dark-600 to-dark-700'>
                    <FontAwesomeIcon className="h-6 text-dark-400" icon={faScrewdriverWrench} /> 
                </span>
              
              <h4 className='mt-4 text-2xl font-semibold text-white'>
                Transformations
              </h4>
              <p className='mt-1 max-w-md text-lg leading-relaxed text-dark-300'>
              Properly massaged and validated data improves quality, reliability and protects downstream applications from issues such as null values, unexpected duplicates or incompatible formats.              </p>
            </div>
  
            <div className='flex relative flex-col w-full'>
                <span className='flex justify-center items-center w-16 h-16 bg-gradient-to-r rounded-3xl shadow-xl from-dark-600 to-dark-700'>
                    <FontAwesomeIcon className="h-6 text-dark-400" icon={faBrain} /> 
                </span>
            
              <h4 className='mt-4 text-2xl font-semibold text-white'>
                Machine Learning
              </h4>
              <p className='mt-1 max-w-md text-lg leading-relaxed text-dark-300'>
                Determining if machine learning is even the right fit comes first. From there we can help develop and iterate model prototypes before considering integration into your infrastructure.
            </p>
            </div>
  
            <div className='flex relative flex-col w-full'>
                <span className='flex justify-center items-center w-16 h-16 bg-gradient-to-r rounded-3xl shadow-xl from-dark-600 to-dark-700'>
                    <FontAwesomeIcon className="h-6 text-dark-400" icon={faChartColumn} /> 
                </span>
              <h4 className='mt-4 text-2xl font-semibold text-white'>
                Visualizations
              </h4>
              <p className='mt-1 max-w-md text-lg leading-relaxed text-dark-300'>
                Pictures can say a thousand words. Using the right tools and advanced techniques can bring your data to life, enabling complex issues and new patterns to surface.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  )

}

export default DataServiceDetailSection;