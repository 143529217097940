import React from "react";

import DataServiceImage from "../assets/images/work-image-01.jpg"

const DataServiceSection = () => {

    return (<section className='pt-10 pb-10 md:pb-12 md:pt-20 lg:pt-36 bg-dark-800'>

    <div className='mx-auto w-full max-w-screen-xl'>
  
      <div className='px-0 w-full md:grid md:grid-cols-2 lg:gap-x-12 xl:gap-x-36 md:gap-8 lg:px-6'>
        
        <div className='flex flex-col justify-center px-4 sm:px-6 md:pl-0 md:order-2 lg:px-0'>
          <div>
            <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
            Data Engineering & ML Services
            </p>
          </div>
          <h3 className='mt-4 text-3xl font-extrabold text-white md:mt-6 sm:text-4xl lg:text-5xl'>
            Discover Actionable Insights
          </h3>
          <p className='mt-4 text-xl md:mt-6 text-dark-300'>
            Data comes in all different sizes, colors and shapes. Organizing your data into information is complex but critical.   
          </p>
          <p className='mt-4 text-xl md:mt-6 text-dark-300'>
           Optimizing your data management and machine learning operations, we help companies focus less on the grunt work and more on building a competitive advantage putting their data into use.          
          </p>
        </div>
  
        <div className='mt-10 md:mt-0 relative w-full md:rounded-r-3xl lg:rounded-l-3xl pt-11/12 md:order-1'>
          <img src={DataServiceImage} className='object-cover object-center absolute inset-y-0 w-full h-full shadow-xl md:rounded-r-3xl lg:rounded-l-3xl' />
        </div>
      </div>
      
    </div>
  </section>
  )

}

export default DataServiceSection;