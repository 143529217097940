import React from "react";
import HeroServices from "../assets/images/books_glasses_crop2.jpg";

const HeroServicesSection = () => {

    return (
    <section className='px-4 pt-12 pb-10 md:pt-16 md:pb-20 sm:px-6 lg:px-8 bg-dark-900 lg:pb-64'>
    <div className='mx-auto max-w-screen-xl'>
      <div>
        <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
          Our services
        </p> 
        <h1 className="mt-6 text-4xl font-extrabold text-left text-white sm:text-5xl md:text-6xl">
        We provide meaningful technology services to level up your business
        </h1>
      </div>
    
      <div className='relative mt-6 md:mt-8 lg:mt-12 xl:mt-16'>
        <div className='grid z-10 gap-x-8 lg:grid-cols-2 lg:absolute'>
          <div>
            <p className="max-w-lg text-xl lg:mx-auto text-dark-300 sm:max-w-3xl">
                Longitudinal Labs brings commercial awareness and a business-minded approach to technology, we are not just another "dev" shop. 
            </p>
            <p className="max-w-lg text-xl lg:mx-auto text-dark-300 sm:max-w-3xl pt-6">
                We are committed to studying your early-stage company's needs, market and customers inside and out. Let's strategize and develop your technology roadmap, together.
            </p>

          </div>
    
          <div className='grid mx-auto mt-10 md:mt-12 sm:grid-cols-1 sm:gap-x-4 lg:mt-0 sm:mx-0'>
            <img src={HeroServices} className='hidden object-cover object-center mt-6 w-full rounded-3xl shadow-xl sm:block sm:mt-0 lg:h-96' />
          </div>
        </div>
      </div> 
    </div>
  </section>
  )

}

export default HeroServicesSection;