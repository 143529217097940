import React from "react";

import { HomeHeroSection, HomeIndustrySection, WhatWeDoSection, CTASection,
        DiagnalDownRightDarkLightSection } from "../components";

const HomeView = () => {


    return (
        <>
            <HomeHeroSection /> 
            <HomeIndustrySection />
            <DiagnalDownRightDarkLightSection />
            <WhatWeDoSection />
            <CTASection />
        </>
        )
}

export default HomeView;
