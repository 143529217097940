import React from "react";

const DiagnalUpRightDarkLightSection = () => 
{

    return (<div className='relative w-full h-16 md:h-32 bg-dark-900'>
    <svg className='absolute w-full h-full text-dark-800' preserveAspectRatio='none' viewBox='0 0 100 100' fill='currentcolor'>
      <polygon points='0,100 100,100 100,0'/>
    </svg>
  </div>
  )

}

export default DiagnalUpRightDarkLightSection;