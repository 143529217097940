import React, {useState} from "react";
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMedium, faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const ContactView = () => {


    const [message, setMessage] = useState('');
    const [phone, setPhone] = useState('');
    const [fullName, setFullName] = useState('');
    const [fromAddress, setFromAddress] = useState('');
    const [emailSent, setEmailSent] = useState(false);
  
  
    function sendEmail(e) {
      e.preventDefault();
  
      emailjs.send('service_1a1l7l8', 'template_jy65mb2', { message: message, from_address : fromAddress, full_name : fullName, phone: phone}, 'pBI-w5VmhBLO9G6tM')
        .then((result) => {
          setEmailSent(true);
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
  
      
    }



    return (
            <section className='py-12 px-4 md:py-16 sm:px-6 lg:px-8'>
            <div className='mx-auto max-w-screen-xl'>
                <div className='grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16'>
                <div className='flex flex-col justify-between items-start sm:items-center lg:items-start'>

                    <div>
                    <div className='w-full text-left sm:text-center lg:text-left'>
                        <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
                        Contact
                        </p>
                    </div>
                    <div className='mt-4 w-full text-left md:mt-5 sm:text-center lg:text-left'>
                        <h1 className="text-4xl font-extrabold text-white sm:text-5xl md:text-6xl">
                        Get in touch
                        </h1>
                        <p className="mx-auto mt-3 max-w-lg text-xl md:mt-5 text-dark-300 sm:max-w-2xl">
                        We'd love to hear from you. Fill in the form and we'll get back to you shortly.
                        </p>
                    </div>
                    </div>

                    <div className='grid gap-6 mt-8 sm:mt-10 lg:mt-0 sm:grid-cols-2 sm:grid-rows-2 sm:gap-12'>
                    
                    <div>
                        <h5 className='text-lg font-bold text-white'>
                        San Diego
                        </h5>
                        <p className='mt-1 text-sm text-base text-dark-300'>
                        1111 Sixth Ave
                        <br />San Digeo, CA 92101
                        </p>
                    </div>
                    </div>

                    <div className='mt-8 w-full sm:mt-10 lg:mt-0'>
                    <h6 className='text-sm text-lg font-semibold text-left text-white sm:text-center lg:text-left'>
                        Follow us
                    </h6>

                    <div className='flex justify-start mt-3 space-x-4 sm:justify-center lg:justify-start'>

                      

                        <a className="flex justify-center items-center w-12 h-12 rounded-full transition duration-300 ease-in-out bg-dark-700 hover:text-dark-900 hover:bg-white  text-dark-300" href="https://medium.com">
                          <FontAwesomeIcon className="icon icon-tabler icon-tabler-brand-medium" icon={faMedium} />
                        </a>

                        <a className="flex justify-center items-center w-12 h-12 rounded-full transition duration-300 ease-in-out bg-dark-700 hover:text-dark-900 hover:bg-white  text-dark-300" href="https://github.com/orgs/longitudinal-labs" >
                            <FontAwesomeIcon className="icon icon-tabler icon-tabler-brand-medium" icon={faGithub} />
                        </a>

                        <a className="flex justify-center items-center w-12 h-12 rounded-full transition duration-300 ease-in-out bg-dark-700 hover:text-dark-900 hover:bg-white  text-dark-300" href="https://www.linkedin.com/company/longitudinal-labs">
                            <FontAwesomeIcon className="icon icon-tabler icon-tabler-brand-medium" icon={faLinkedin} />
                        </a>
                    </div>
                    </div>
                </div>

                <div className='mt-12 w-full lg:mt-0'>
                    <div className='py-12 px-4 mx-auto w-full rounded-3xl shadow-xl lg:mr-0 lg:ml-auto bg-dark-700 sm:p-16 lg:p-14 xl:p-16'>
                    {!emailSent ? (
                    <form onSubmit={sendEmail}>
                        <div>
                        <label htmlFor="name" className='ml-0.5 text-dark-300 font-medium text-sm'>
                            Name *
                        </label>
                        <input id="name" type='text' name='name'  onChange={e => setFullName(e.target.value)} placeholder='John Doe' className='p-4 mt-2 w-full h-14 text-sm font-medium text-white rounded-2xl border-2 border-solid transition duration-200 ease-in-out outline-none bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none' required />
                        </div>
            
                        <div className='mt-6'>
                        <label htmlFor="email" className='ml-0.5 text-dark-300 font-medium text-sm'>
                            Email *
                        </label>
                        <input id="email" type='email' name='email' onChange={e => setFromAddress(e.target.value)} placeholder='john@email.com' className='p-4 mt-2 w-full h-14 text-sm font-medium text-white rounded-2xl border-2 border-solid transition duration-200 ease-in-out outline-none bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none' required />
                        </div>
            
                        <div className='mt-6'>
                        <label htmlFor="phone" className='ml-0.5 text-dark-300 font-medium text-sm'>
                            Phone
                        </label>
                        <input id="phone" type='text' name='phone' placeholder='(123) 456-789' onChange={e => setPhone(e.target.value)} className='p-4 mt-2 w-full h-14 text-sm font-medium text-white rounded-2xl border-2 border-solid transition duration-200 ease-in-out outline-none bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none' />
                        </div>

                        <div className='mt-6'>
                        <label htmlFor="message" className='ml-0.5 text-dark-300 font-medium text-sm'>
                            Message *
                        </label>
                        <textarea id="message" type='text' name='message' placeholder='Message' rows='5' onChange={e => setMessage(e.target.value)} className='p-4 mt-2 w-full text-sm font-medium text-white rounded-2xl border-2 border-solid transition duration-200 ease-in-out outline-none bg-dark-800 border-dark-800 focus:border-dark-600 focus:outline-none' required></textarea>
                        </div>
            
                        <div className='flex justify-start mt-6'>
                        <button type='submit' className="flex justify-center items-center py-4 px-8 w-auto h-14 text-base font-semibold leading-snug bg-white rounded-full transition ease-in-out duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-900">
                            Send message
                        </button>
                        </div>
                    </form>
                    ): 
                    <div className="h-96">
                        <h5 className='text-lg font-bold text-white'>
                        Thanks for dropping us a message! <br /><br/> We'll get back to you soon.
                        </h5>
                    </div>}
                    </div>
                </div>
                </div>
            </div>
            </section>
    )
}

export default ContactView