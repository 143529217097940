import React from "react";

import Handbook from "../assets/images/handbook.jpg";


const HandbookSection = () => {

    return (

        <section className='px-4 pb-10 pb-12 md:pt-4 md:pb-16 sm:px-6 lg:px-8 bg-dark-900'>
        <div className='mx-auto w-full max-w-screen-xl'>
        <div className='mx-auto mt-24 max-w-xl md:max-w-2xl lg:mt-32 lg:max-w-none lg:mx-0 lg:grid lg:grid-cols-3 lg:gap-x-16 xl:gap-x-20 2xl:gap-x-24'>

        <div className='col-span-2 lg:order-2'>
          <div className='relative text-center lg:text-left'>
            <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
              Our handbook
            </p>
            <h2 className='mt-4 text-3xl font-extrabold text-white lg:mt-6 sm:text-4xl md:text-5xl'>
              How we work and collaborate with our clients
            </h2>
            <p className='mt-4 text-xl lg:mt-6 text-dark-300'>
                Our only order of business is making sure we solve your needs. Our solutions will always be tailored for the job at hand, never using technology for "technology sake"</p>
            <div className='flex flex-col mx-auto mt-8 max-w-xs sm:mt-10 sm:mx-0 sm:flex-row sm:max-w-none'>
              <div className='flex flex-col items-center lg:flex-start lg:items-start'>
                <div className='flex flex-col items-center sm:flex-row'>
                  <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-bulb" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#9e9e9e" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M3 12h1m8 -9v1m8 8h1m-15.4 -6.4l.7 .7m12.1 -.7l-.7 .7" />
                    <path d="M9 16a5 5 0 1 1 6 0a3.5 3.5 0 0 0 -1 3a2 2 0 0 1 -4 0a3.5 3.5 0 0 0 -1 -3" />
                    <line x1="9.7" y1="17" x2="14.3" y2="17" />
                    </svg>
                  </div>
                  <div className="mt-3 text-xl font-semibold text-white sm:mt-0 sm:ml-3 ">
                    Identify real problems
                  </div>
                </div>
                <p className="mt-1 text-lg leading-relaxed sm:mt-3 text-dark-300">
                    We are committed to understanding your company's needs, market and customers so we address the right questions.  
                </p>
              </div>
              <div className='flex flex-col items-center mt-8 sm:mt-0 md:flex-start lg:items-start lg:ml-6'>
                <div className='flex flex-col items-center sm:flex-row'>
                  <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6 text-dark-300" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                      <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                    </svg>
                  </div>
                  
                  <div className="mt-3 text-xl font-semibold text-white sm:mt-0 sm:ml-3">
                    Clear communication
                  </div>
                </div>
                <p className="mt-1 text-lg leading-relaxed sm:mt-3 text-dark-300">
                    Communication is a two way street. Excellent verbal skills are only part of it. We pride ourselves in being great listeners.
                </p>
              </div>
            </div>
          </div>
        </div>
            <div className='flex col-span-1 items-center mt-10 lg:mt-0 lg:order-1'>
                <div className='relative w-full text-center rounded-3xl shadow-xl pt-3/2'>
                <img className='object-cover object-center absolute top-0 left-0 w-full h-full align-middle rounded-3xl' src={Handbook} />
                </div>
            </div>
        </div>
    </div>
    </section>
  
    )

}

export default HandbookSection;