import React from "react";

const MvpProcessSection = () => {

    return (<section className='px-4 pt-24 pb-24 mx-auto w-full max-w-screen-xl sm:px-6 lg:px-8'>  

    <div className='mx-auto w-full max-w-xl text-center lg:max-w-3xl md:max-w-2xl'>
      <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
        How it works
      </p>
      <h2 className='mt-6 text-3xl font-extrabold text-white sm:text-4xl md:text-5xl'>
        Experience our development process
      </h2>
      <p className='mt-6 text-xl text-dark-300'>
        Shape a vision, build a strategy, create a roadmap.   
      </p>
    </div>
  
    <div className='grid gap-10 mx-auto mt-12 max-w-xl lg:mt-16 lg:grid-cols-4 lg:grid-x-16 lg:max-w-none'>
  
      <div className='w-full'>
        <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-xl w-18 h-18 from-dark-600 to-dark-700'>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-10 h-10 text-dark-300" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
            <rect x="9" y="3" width="6" height="4" rx="2" />
            <line x1="9" y1="12" x2="9.01" y2="12" />
            <line x1="13" y1="12" x2="15" y2="12" />
            <line x1="9" y1="16" x2="9.01" y2="16" />
            <line x1="13" y1="16" x2="15" y2="16" />
          </svg>
        </span>
        <h3 className='mt-5 text-2xl font-semibold text-center text-white'>
          1. Kickoff
        </h3>
        <p className='mt-2 text-lg leading-relaxed text-center text-dark-300'>
            This is our chance to get to know each other. We dive into understanding your business, objectives and market needs in order to identify value.
        </p>
      </div>

      <div className='w-full'>
        <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-xl w-18 h-18 from-dark-600 to-dark-700'>
        <svg className='w-10 h-10 text-dark-300' xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <rect x="8" y="8" width="8" height="8" rx="1" />
            <line x1="3" y1="8" x2="4" y2="8" />
            <line x1="3" y1="16" x2="4" y2="16" />
            <line x1="8" y1="3" x2="8" y2="4" />
            <line x1="16" y1="3" x2="16" y2="4" />
            <line x1="20" y1="8" x2="21" y2="8" />
            <line x1="20" y1="16" x2="21" y2="16" />
            <line x1="8" y1="20" x2="8" y2="21" />
            <line x1="16" y1="20" x2="16" y2="21" />
          </svg>        </span>
        <h3 className='mt-5 text-2xl font-semibold text-center text-white'>
          2. Ideate 
        </h3>
        <p className='mt-2 text-lg leading-relaxed text-center text-dark-300'>
            Define and shape your vision. Plan and evaluate the essential features to accomplish your objectives, eliminate potential mistakes and iterate.        
        </p>
      </div>
  
      <div className='w-full'>
        <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-xl w-18 h-18 from-dark-600 to-dark-700'>
          
        <svg className='w-10 h-10 text-dark-300' xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25" />
            <circle cx="7.5" cy="10.5" r=".5" fill="currentColor" />
            <circle cx="12" cy="7.5" r=".5" fill="currentColor" />
            <circle cx="16.5" cy="10.5" r=".5" fill="currentColor" />
            </svg>
        </span>
  
        <h3 className='mt-5 text-2xl font-semibold text-center text-white'>
          3. Design
        </h3>
        <p className='mt-2 text-lg leading-relaxed text-center text-dark-300'>
            Build user journey maps, create mock user interfaces, develop rapid prototypes using no-code tooling, solicit user feedback, analyze and iterate.
        </p>
      </div>
  
      <div className='w-full'>
        <span className='flex justify-center items-center mx-auto bg-gradient-to-r rounded-3xl shadow-xl w-18 h-18 from-dark-600 to-dark-700'>
          <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10 text-dark-300' width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
            <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
            <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
            <circle cx="15" cy="9" r="1" />
          </svg>
        </span>
        <h3 className='mt-5 text-2xl font-semibold text-center text-white'>
          4. Develop
        </h3>
        <p className='mt-2 text-lg leading-relaxed text-center text-dark-300'>
         Build a beautiful, fast, and secure product based on our design research. We will handle launching and training your support staff.  
        </p>
      </div>
    </div>
  </section>
  )

}

export default MvpProcessSection;