import React, {useState} from "react";
import { Link } from "react-router-dom";

import logo from "../assets/images/IconOnly_Transparent_NoBuffer.png"

const NavigationHeader = () => {


    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    const onHamburgerClick = () => {
      setHamburgerOpen(!hamburgerOpen);
    }

    return (
    <nav className='flex items-center px-4 pt-6 mx-auto max-w-7xl sm:px-6 lg:px-8'>
        <div className='flex justify-between items-center w-full'>

            <div className='flex items-center'>

                <Link to="/" className='block text-2xl font-black md:hidden lg:block group mr-2'>
                    <img className='w-16' src={logo} />
                </Link>

                <Link to="/" className='ml-2 blocktext-2xl font-black md:hidden lg:block group'>
                <span className='text-white transition duration-200 ease-in-out group-hover:text-dark-300'>Longitudinal</span>
                <span className='transition duration-200 ease-in-out text-dark-300 pl-1 group-hover:text-white'>Labs</span>
                </Link>

                <Link to="/" className='hidden text-3xl font-black md:block lg:hidden group'>
                <span className='text-white transition duration-200 ease-in-out group-hover:text-dark-300'>L</span><span className='transition duration-200 ease-in-out text-dark-300 group-hover:text-white'>Labs</span>
                </Link>
            </div>

            <div className='hidden md:flex justify-between items-center md:space-x-0.5 lg:space-x-2 text-xl md:text-base font-medium text-dark-300'>
                <Link to="/" className="block py-1 px-4 rounded-full transition duration-200 ease-in-out sm:inline-block hover:text-white hover:bg-dark-700">
                Home
                </Link>
                <Link to="/services" className="block py-1 px-4 rounded-full transition duration-200 ease-in-out sm:inline-block hover:text-white hover:bg-dark-700">
                Services
                </Link>
                <Link to="/about" className="block py-1 px-4 rounded-full transition duration-200 ease-in-out sm:inline-block hover:text-white hover:bg-dark-700">
                About
                </Link>
            </div>

            <div className='hidden md:block'>
                <Link to="/contact" className="flex justify-center items-center py-3 px-8 w-auto text-base font-semibold leading-snug bg-white rounded-full transition ease-in-out duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-800">
                Let's chat
                </Link>
            </div>
        </div>
        <div className='block md:hidden'>
          <button className={`relative z-50 w-6 h-5 transition duration-500 ease-in-out transform rotate-0 cursor-pointer group focus:outline-none ${hamburgerOpen ? 'js-hamburger-open' : '' }`} onClick={onHamburgerClick} >
            <span className='block absolute top-0 left-0 w-full h-1 rounded-full opacity-100 transition duration-200 ease-in-out transform rotate-0 bg-dark-300 group-hover:bg-white'></span>
            <span className='block absolute left-0 top-2 w-full h-1 rounded-full opacity-100 transition duration-200 ease-in-out transform rotate-0 bg-dark-300 group-hover:bg-white'></span>
            <span className='block absolute left-0 top-2 w-full h-1 rounded-full opacity-100 transition duration-200 ease-in-out transform rotate-0 bg-dark-300 group-hover:bg-white'></span>
            <span className='block absolute left-0 top-4 w-full h-1 rounded-full opacity-100 transition duration-200 ease-in-out transform rotate-0 bg-dark-300 group-hover:bg-white'></span>
          </button>

          <div className={`hidden absolute top-0 left-0 z-40 justify-center items-center w-screen h-screen bg-gradient-to-tr from-dark-800 to-dark-900 ${hamburgerOpen ? 'js-mobileNav-open' : '' } ` }>
            <div className='flex flex-col justify-evenly items-center p-4 mx-auto space-y-8 w-full text-xl'>
              <Link to="/" className="block py-2 px-6 font-medium rounded-full transition duration-200 ease-in-out text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block" onClick={onHamburgerClick}>
                Home
              </Link>
              <Link to="/services" className="block py-2 px-6 font-medium rounded-full transition duration-200 ease-in-out text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block" onClick={onHamburgerClick}>
                Services
              </Link>
              <Link to="/about" className="block py-2 px-6 font-medium rounded-full transition duration-200 ease-in-out text-dark-300 hover:text-white hover:bg-dark-700 sm:inline-block" onClick={onHamburgerClick}>
                About
              </Link>
              <Link className="flex justify-center items-center py-4 px-8 w-auto text-lg font-semibold leading-snug bg-white rounded-full transition ease-in-out duration-250 text-dark-900 hover:text-white focus:outline-none hover:bg-dark-700" to="/contact" onClick={onHamburgerClick}>
                Let's chat
              </Link>
            </div>
          </div>
        </div>
    </nav>

    )


}

export default NavigationHeader