import React from "react";

import FractionCtoImage from "../assets/images/features-alternating-with-list-02.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faRocket, faMapLocationDot, faPeopleGroup, faMagnifyingGlass, faCode  } from "@fortawesome/free-solid-svg-icons"


const FractionalCtoSection = () => {

    return (<section className='px-4 pt-10 pb-12 md:pt-12 sm:pb-16 bg-dark-800 sm:px-6 lg:px-8'>

    <div className='mx-auto max-w-screen-xl'>
  
      <div className="relative mt-24 lg:mt-32 lg:grid lg:grid-cols-2 lg:auto-rows-max lg:gap-x-12">
  
        <div className='flex flex-col justify-center items-center lg:order-2 lg:items-start'>
          <div className='w-full text-center lg:text-left'>
            <p className="inline-flex justify-center items-center py-2 px-6 text-sm font-medium tracking-wide text-white bg-gradient-to-r rounded-r-full rounded-tl-full from-dark-600 to-dark-700">
              Fractional CTO
            </p>
          </div>
          <div className='mt-4 w-full max-w-lg text-center lg:mt-6 lg:text-left sm:max-w-2xl'>
            <h3 className="text-3xl font-extrabold text-white sm:text-4xl md:text-5xl">
                Partial CTO coverage for early-stage ventures
            </h3>
            <p className="mx-auto mt-4 text-xl lg:mt-6 text-dark-300">
                Need help with specific, urgent needs such as strategic planning, infrastructure management, or product development?
            </p>
          </div>
          <div className='mt-6 space-y-2 lg:mt-8'>
            <div className='flex items-center text-lg text-dark-300'>
                <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                    <FontAwesomeIcon className="h-6 text-dark-400" icon={faMapLocationDot} /> 
                </div>
                <span className="pl-2">Build out technical roadmap and execution strategy.</span>
            </div>
            <div className='flex items-center text-lg text-dark-300'>
                <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                <FontAwesomeIcon className="h-6 text-dark-400" icon={faRocket} /> 
                </div>
                <span className="pl-2">Guide new product or service launch.</span>
            </div>
            <div className='flex items-center text-lg text-dark-300'>
                <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                <FontAwesomeIcon className="h-6 text-dark-400" icon={faPeopleGroup} /> 
                </div>
                <span className="pl-2">Mentor teams to prioritize and shorten delivery times.</span>
            </div>
            <div className='flex items-center text-lg text-dark-300'>
                <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                <FontAwesomeIcon className="h-6 text-dark-400" icon={faCode} />
                </div>
                <span className="pl-2">Analysis of an existing product or infrastructure design.</span>
            </div>
            <div className='flex items-center text-lg text-dark-300'>
                <div className="flex justify-center items-center w-12 h-12 bg-gradient-to-r rounded-2xl from-dark-600 to-dark-700">
                <FontAwesomeIcon className="h-6 text-dark-400" icon={faMagnifyingGlass} />
                </div>
                <span className="pl-2">In-depth technical research for your next funding round.</span>
            </div>
          </div>
        </div>
  
        <div className='flex relative justify-center items-center mx-auto mt-10 max-w-lg lg:order-1 lg:mt-0 lg:max-w-none'>
          <div className='absolute w-full h-full rounded-3xl bg-dark-700 2xl:right-10 2xl:top-10 lg:top-4 lg:right-4 sm:top-8 sm:right-8 '>
            <svg className='absolute top-0 right-1/4 inset-y-0 h-full text-dark-700 w-1/4 z-20' preserveAspectRatio='none' viewBox='0 0 100 100' fill='currentcolor'>
              <polygon points='0,0 100,0 0,100'/>
            </svg>
            <div className='absolute w-1/2 inset-y-0 h-full left-1/2 bg-dark-900 z-10 rounded-r-3xl'></div>
          </div>
          <img src={FractionCtoImage} className='object-cover z-30 w-auto h-full rounded-3xl shadow-xl' />
        </div>
      </div>
    </div>
  </section>)

}

export default FractionalCtoSection;