import React from "react";


import { HomeView, ServicesView, AboutView, ContactView } from "../views"
import { NavigationHeader, Footer } from "../components";

import { Route, Routes, BrowserRouter as Router } from "react-router-dom";



const App = () => {


  return (
    <Router>
      <div id="app" className='bg-dark-900'>
          <NavigationHeader />
          <Routes>
              <Route path="/" element={<HomeView />} />
              <Route path="/services" element={<ServicesView />} />
              <Route path="/about" element={<AboutView />} />
              <Route path="/contact" element={<ContactView />} />
              <Route path="*" element={<HomeView />} />
          </Routes>
          <Footer />
      </div>
    </Router>

  );
}

export default App;
